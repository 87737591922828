<template>
  <div class="">
    <div id="preloader" v-if="!ready">
      <div data-loader="circle-side"></div>
    </div>
    <!-- /Preload -->

    <div id="loader_form">
      <div data-loader="circle-side-2"></div>
    </div>
    <!-- /loader_form -->

    <nav>
      <ul class="cd-primary-nav">
        <li>
          <a href="http://meizf.nl" class="animated_link">Home</a>
        </li>
      </ul>
    </nav>
    <!-- /menu -->

    <div class="container-fluid full-height">
      <div class="row row-height">
        <div class="col-lg-6 content-left no-gutters">
          <div class="content-left-wrapper">
            <!-- <a href="index.html" id="logo"
              ><img src="img/logo.png" alt="" width="49" height="35"
            /></a> -->
            <div id="social">
              <ul>
                <!--
                <li>
                  <a href="#0"><i class="icon-google"></i></a>
                </li>
                -->
                <li>
                  <a href="https://in.linkedin.com"
                    ><i class="icon-linkedin"></i
                  ></a>
                </li>
              </ul>
            </div>
            <!-- /social -->
            <div>
              <figure>
                <img src="/img/meizf-logo.png" alt="" class="img-fluid" />
              </figure>
              <h2>IOSS number decryption form</h2>
              <p></p>
            </div>
            <div class="copy">
              <a
                class="btn border-none text-light"
                href="https://meizf.nl"
                target="_blank"
                >© 2021 Mei Z&F</a
              >

              <a
                class="btn border-none text-light"
                href="https://vat4u.com"
                target="_blank"
                >Powered by VAT4U</a
              >
            </div>
          </div>
          <!-- /content-left-wrapper -->
        </div>
        <!-- /content-left -->

        <div class="col-lg-6 content-right no-gutters" id="start">
          <div id="wizard_container">
            <div id="top-wizard">
              <div id="progressbar"></div>
            </div>

            <form>
              <div v-if="!showDecryptedData">
                <p>
                  Please provide all the details listed below to decrypt the
                  IOSS number.
                </p>

                <div class="form-group required">
                  <input
                    type="text"
                    name="encryptedNumber"
                    class="form-control required"
                    placeholder="Your encrypted code"
                    v-model="encryptedNumber"
                    id="firstFormField"
                  />
                </div>
                <div class="form-group required">
                  <input
                    type="text"
                    name="email"
                    class="form-control required"
                    placeholder="Authorized email"
                    v-model="username"
                  />
                </div>
                <div class="form-group required">
                  <input
                    type="password"
                    name="key"
                    class="form-control required"
                    placeholder="Your decryption key"
                    v-model="userKey"
                  />
                </div>

                <div>
                  <p class="text-danger">{{ error }}</p>
                </div>
              </div>
              <div v-else class="text-center">
                <p>
                  Official IOSS number:
                  <span style="font-weight: 800">{{ decryptedNumber }}</span>
                </p>
              </div>
            </form>

            <div class="text-center">
              <button
                v-if="showDecryptedData"
                @click="decrypt"
                class="backward"
                type="button"
              >
                Back
              </button>
              <div v-else>
                <p class="text-justify text-dimmed">
                  Mei Z&F puts the security of the client at a high
                  priority. Therefore, we have put effort into ensuring that the
                  IOSS number is only provided to the appropriate recipient. By
                  clicking on the button Decrypt you confirm that you accept to
                  keep the IOSS number as well as any data displayed on this
                  page strictly confidential and understand that it is strictly
                  forbidden to share with another person or company. In order to
                  strengthen the security of this process, we store your IP
                  address as well as any attempt to decrypt the IOSS number.
                </p>
                <button @click="decrypt" class="forward" type="button">
                  Decrypt
                </button>
              </div>
            </div>
          </div>
          <!-- /Wizard container -->
        </div>
        <!-- /content-right-->
      </div>
      <!-- /row-->
    </div>
    <!-- /container-fluid -->

    <div class="cd-overlay-nav">
      <span></span>
    </div>
    <!-- /cd-overlay-nav -->

    <div class="cd-overlay-content">
      <span></span>
    </div>
    <!-- /cd-overlay-content -->

    <a class="cd-nav-trigger" @click="toggleNav"
      >Menu<span class="cd-icon"></span
    ></a>
    <!-- /menu button -->

    <!-- Modal terms -->
    <div
      class="modal fade"
      id="terms-txt"
      tabindex="-1"
      role="dialog"
      aria-labelledby="termsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="termsLabel">Terms and conditions</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div class="modal-body"></div>
          <div class="modal-footer">
            <button type="button" class="btn_1" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Velocity from "velocity-animate";

import { IossNumberService } from "@/services/api";

import {
  Steps,
  Select,
  Space,
  Spin,
  Popover,
  Upload,
  Button,
  Icon,
  notification,
} from "ant-design-vue";

Vue.use(Steps);
Vue.use(Select);
Vue.use(Space);
Vue.use(Spin);
Vue.use(Popover);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Upload);
Vue.use(notification);

export default {
  name: "DecryptionForm",
  props: {},
  data: function () {
    return {
      username: "",
      encryptedNumber: "",
      userKey: "",
      decryptedNumber: "",
      sendingData: false,
      showDecryptedData: false,
      ready: false,
      error: "",
    };
  },
  methods: {
    toggleNav() {
      console.log("Toggling navigation");
      var navigation = document.getElementsByClassName("cd-primary-nav")[0],
        toggleNav = document.getElementsByClassName("cd-nav-trigger")[0];

      console.log(toggleNav);

      if (!this.navOpen) {
        //it means navigation is not visible yet - open it and animate navigation layer
        toggleNav.classList.add("close-nav");

        Velocity(
          document.querySelectorAll(".cd-primary-nav span"),
          {
            translateZ: 0,
            scaleX: 1,
            scaleY: 1,
          },
          500,
          "easeInCubic",
          function () {
            navigation.addClass("fade-in");
          }
        );

        Velocity(
          document.querySelectorAll(".cd-overlay-nav span"),
          {
            translateZ: 0,
            scaleX: 1,
            scaleY: 1,
          },
          500,
          "easeInCubic",
          function () {
            navigation.classList.add("fade-in");
          }
        );
      } else {
        //navigation is open - close it and remove navigation layer
        toggleNav.classList.remove("close-nav");

        Velocity(
          document.querySelectorAll(".cd-overlay-content span"),
          {
            translateZ: 0,
            scaleX: 1,
            scaleY: 1,
          },
          500,
          "easeInCubic",
          function () {
            navigation.classList.remove("fade-in");
            console.log("Removing span");
            Velocity(
              document.querySelectorAll(".cd-overlay-content span"),
              {
                translateZ: 0,
                scaleX: 0,
                scaleY: 0,
              },
              0
            );
            Velocity(
              document.querySelectorAll(".cd-overlay-nav span"),
              {
                translateZ: 0,
                scaleX: 0,
                scaleY: 0,
              },
              0
            );
          }
        );

        Velocity(
          document.querySelectorAll(".cd-primary-nav span"),
          {
            translateZ: 0,
            scaleX: 0,
            scaleY: 0,
          },
          0,
          "easeInCubic",
          function () {
            navigation.classList.remove("fade-in");
          }
        );
      }

      this.navOpen = !this.navOpen;
    },
    layerInit() {
      var diameterValue =
        Math.sqrt(
          Math.pow(window.innerHeight, 2) + Math.pow(window.innerWidth, 2)
        ) * 2;

      Velocity(
        document.querySelectorAll(".cd-overlay-nav span"),
        {
          scaleX: 0,
          scaleY: 0,
          translateZ: 0,
        },
        50
      );

      Velocity(
        document.querySelectorAll(".cd-overlay-nav span"),
        {
          height: diameterValue + "px",
          width: diameterValue + "px",
          top: -(diameterValue / 2) + "px",
          left: -(diameterValue / 2) + "px",
        },
        0
      );

      Velocity(
        document.querySelectorAll(".cd-overlay-content span"),
        {
          scaleX: 0,
          scaleY: 0,
          translateZ: 0,
        },
        50
      );
      Velocity(
        document.querySelectorAll(".cd-overlay-content span"),
        {
          height: diameterValue + "px",
          width: diameterValue + "px",
          top: -(diameterValue / 2) + "px",
          left: -(diameterValue / 2) + "px",
        },
        0
      );
    },

    decrypt() {
      if (this.showDecryptedData) {
        this.showDecryptedData = false;
        return;
      }

      this.sendingData = true;
      IossNumberService.decryptData(
        this.username,
        this.encryptedNumber,
        this.userKey
      )
        .then((response) => {
          this.decryptedNumber = response.data.data.iossNumber;
          this.showDecryptedData = true;
        })
        .catch((error) => {
          console.error("error", error);
          (this.error =
            "Decryption failed. Please check provided data and try again."),
            (this.showDecryptedData = false);
        })
        .finally(() => {
          this.sendingData = false;
        });
    },
  },
  computed: {},
  mounted() {
    this.ready = true;
    this.layerInit();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.no-gutters {
  padding-right: 0;
  padding-left: 0;
}
</style>

<style>
.form-group.required::after {
  content: " *";
  color: red;
  display: inline-block;
  position: absolute;
  right: 5px;
  top: 0px;
}
.input-group.required::after {
  content: " *";
  color: red;
  display: inline-block;
  position: absolute;
  right: 45px;
  top: 0px;
}

.text-dimmed {
    font-size: 9pt;
    color: #777;
}

</style>

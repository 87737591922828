<template>
  <div>
    <div class="form-group terms">
      <label class="container_check"
        >I confirm that the information I have completed on this form is
        accurate and complete.
        <input type="checkbox" name="terms" value="Yes" class="required" v-model="userConfirmation" />
        <span class="checkmark"></span>
      </label>
    </div>

    <div class="form-group terms">
      <label class="container_check"
        >I acknowledge that by submitting this form I authorise the transfer of
        the information provided to Mei Z&F in order to allow Mei Z&F to assess and validate its content and my application. I understand
        that the submission of this form does not guarantee that
        my registration for the IOSS scheme will be accepted. In its sole
        discretion, Mei Z&F may accept or not my registration after
        assessing the information provided. The commercial and legal
        relationship between by company and Mei Z&Fwill take the form of
        a separate contract.
        <input type="checkbox" name="terms" value="Yes" class="required" v-model="userAcknowledgement" />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</template>

<script>

import { createComputed } from "@/services/utilities";
export default {
  name: "Step9",
  computed: {
    userConfirmation: createComputed("form", "form.userConfirmation"),
    userAcknowledgement: createComputed("form", "form.userAcknowledgement"),
  },
};
</script>

